<template lang="pug">
  #anniversary.pb-5.bg-td-grey-darker.text-white.overflow-x-hidden.w-100
    LoadingSpinnerOverlay(v-if="isLoading")
    .hero
      div(style="padding:10vh 0 5vh 0")
        AnniversaryEventHeader
      .text-center(style="font-size:6rem")
        a.cursor-pointer.text-anniversary-light(@click="$scrollTo('#signup-anchor')")
          font-awesome-icon(icon="chevron-down")
      div.ribbon-container.position-relative(style="height:25px;")
      .teaser
        img.w-100.img-fluid(src="https://s3-cloud.td-cdn.de/Images/Promo/30-Jahre-TD/mood.jpg")
    div.ribbon-container.position-relative(style="height:25px;margin-bottom:5rem;")
      div.position-absolute.ribbon
        img.w-100.img-fluid(src="https://s3-cloud.td-cdn.de/Images/Promo/30-Jahre-TD/schleife.png")
    section.container.pb-4
      b Wir feiern 30 Jahre touriDat
      span &nbsp– 30 Jahre voller Erinnerungen, Abenteuer und unvergesslicher Erlebnisse! Kommt mit auf eine spannende Reise durch unsere Geschichte und entdeckt, was uns ausmacht.
      br
      span Als Dank für eure langjährige Treue haben wir exklusive Aktionen vorbereitet, um euch etwas zurückzugeben und gemeinsam zu feiern!
    #signup-anchor
    section.container.py-4
      .box.bg-anniversary-dark.p-3.mb-4 Jeden Monat verlosen wir eine wunderbare Reise aus unserem großen touriDat-Portfolio!
      .box.bg-anniversary-dark.p-3 Zusätzlich zwischen drei erlesenen Angeboten wählen und 30€ extra Rabatt mit dem jeweiligen Code erhalten!
    section.container
      hr(style="margin-top: 3.5rem;margin-bottom: 1.75rem;")
    AnniversarySignupSection(:contest="contest" :session="contestSession" v-if="contest")
    section(v-if="currentPrize")
      .container-headline.mx-4
        .d-inline-block.bg-td-grey-darker.my-4.p-3 Gewinn des Monats
      .container
        router-link.d-block.bg-anniversary-dark.current-prize.position-relative(:to="currentPrizeUrl" target="_blank" style="text-decoration:none;border-radius:0 0 10px 10px")
          .px-4.pt-4.position-relative
            .image-gallery.w-100(v-if="getGalleryImages(currentPrize).length > 1")
              .image.d-md-block(v-for="image in getGalleryImages(currentPrize)")
                img.img-fluid(:src="image.RemoteURL")
            .single-image.w-100(v-else)
              img.img-fluid(:src="currentPrize.ImageUrl")
            .row
              .col-12.col-md-4.text-center.text-md-right.order-md-1
                .value-badge.aspect-square.rounded-circle.d-inline-flex.align-items-center.justify-content-center.bg-anniversary-basic(style="transform: translateY(-50%);margin-bottom: -60px;" v-if="currentItem && currentItem.RegularPrice")
                  .text-center.font-weight-semibold
                    | Wert:
                    br
                    | {{currentItem.RegularPrice | formatPrice}}
              .col-12.col-md-8.order-md-0.d-flex
                .text-center.text-md-left.title.align-self-center.w-100
                  .h2.m-0.mt-2.mt-md-0
                    span.stars.mr-1(v-if="currentItem.Host.Stars" style="font-size:90%;")
                      font-awesome-icon(icon="star" v-for="n in parseInt(currentItem.Host.Stars)")
                      font-awesome-icon(icon="s" v-if="isHostSuperior(currentItem.Host)")
                    span.name {{currentPrize.HtmlHeadline}}
            .hr-faded.my-2.mt-md-0
            div
              .row.small
                .col-12.col-md-6.text-center.text-md-left {{currentPrize.HtmlSubline}}
                .col-12.col-md-6.text-center.text-md-right
                  .d-inline-flex.align-items-center
                    font-awesome-icon.mr-1(icon="map-marker-alt")
                    span {{currentPrize.Item.Host.CityRegion}}
          HostPreviewFooter.bg-anniversary-darker.py-2.mt-2.txt-td-grey-darker(:persons="currentPrize.Item.Persons" :nights="currentPrize.Item.Nights" :catering="currentPrize.Item.Catering" style="border-radius:0 0 10px 10px")
        .text-center.mt-4(v-if="showsWinnerPageLink")
          button.button.button-primary.button-anniversary.w-100
            .h2.m-0 zu den Gewinnern
    section.pb-4(v-if="currentDiscountPrizes && currentDiscountPrizes.length")
      .container-headline.mx-4
        .d-inline-block.bg-td-grey-darker.p-3.my-4 Unsere Extra-Rabatte
      .container.position-relative
        .mb-4(v-for="(prize,i) in currentDiscountPrizes")
          DiscountCodeItem.mb-1(:item="prize.item" :class="['txt-td-grey-darker',itemBackgroundClasses[i]]")
            .p-2.txt-td-grey-darker.text-center.code-text.my-2.mb-lg-0.font-weight-semibold
              | {{prize.orderDiscountCode.Value | formatPrice }} EXTRA-Rabatt mit dem Code {{prize.orderDiscountCode.Code}}
              span.small.txt-white.ml-1 *
        .text-right.small.text-anniversary-basic
          span.txt-white *
          | &nbsp;pro Angebot auf 30 Stück limitiert
        router-link.button.button-primary.button-anniversary.w-100.text-center.category-link(:to="`/kategorien/${currentDiscountPrizeCategory.Url}`" target="_blank" v-if="currentDiscountPrizeCategory")
          .h2.m-0
            | weitere Angebote aus der Kategorie&nbsp;
            .d-block.d-md-inline {{currentDiscountPrizeCategory.Name}}
    section.timeline-section
      .px-3(style="top: 0;height: calc(100% - 10rem);")
        .logo-line.position-relative(style="margin-bottom:1rem;")
          div.ribbon-container.position-absolute.w-100(style="height:4px;top:50%;margin-top:-2px;")
          .logo-wrapper.bg-anniversary-basic.rounded-circle.position-absolute.aspect-square(style="height:100%;left:50%;transform:translateX(-50%);")
            LogoComponent.bg-td-grey-darker
        TimelineComponent(:slides="timelineSlides" style="height:100%;")
        .text-md-center.txt-td-grey-light
          .arrow
            font-awesome-icon(icon="chevron-down")
</template>
<script>
import ScalableCheckbox from "@/views/components/partials/inputs/ScalableCheckbox.vue";
import { MarketingPromoContest } from "@/graphql/MarketingPromoContest.ts";
import EventBus from "@/event-bus";
import { getDisplayableHostImages, isHostSuperior } from "@/lib/helper/host";
import DiscountCodeItem from "@/views/static/anniversary/partials/DiscountCodeItem.vue";
import TimelineComponent from "@/views/static/anniversary/partials/timeline/TimelineComponent.vue";
import { WebTemplatesEnum } from "@/utils/WebTemplatesEnum.ts";
import LogoComponent from "@/views/components/logo/LogoComponent.vue";
import { contestSession } from "@/lib/contest/session";
import LoadingSpinnerOverlay from "@/views/components/loading/LoadingSpinnerOverlay.vue";
import HostPreviewFooter from "@/views/components/hostPreview/partials/HostPreviewFooter.vue";
import AnniversaryEventHeader from "@/views/static/anniversary/partials/AnniversaryEventHeader.vue";
import AnniversarySignupSection from "@/views/static/anniversary/partials/AnniversarySignupSection.vue";

export default {
  name: "AnniversaryView",
  components: {
    AnniversarySignupSection,
    AnniversaryEventHeader,
    HostPreviewFooter,
    LoadingSpinnerOverlay,
    LogoComponent,
    DiscountCodeItem,
    ScalableCheckbox,
    TimelineComponent
  },
  setup() {
    const timelineSlides = [
      {
        year: 1995,
        text: "Gründung der Tourisik-Datei. Die telefonische Vermittlung von Gästen steht im Fokus. Alles begann in einem kleinen 2-Raum-Büro."
      },
      {
        year: 1996,
        text: "Der erste Umzug. Aus einem kleinen 2-Raum-Büro wird eine moderne und großzügige Geschäftsstelle. Die telefonische Gästevermittlung wird ausgebaut."
      },
      {
        year: 2000,
        text: "Ein kürzerer Name musste her. Die “Touristik-Datei” wird umbenannt in “touriDat”. Das Zeitalter des Internets hat begonnen. Jetzt findet man touriDat unter www.touridat.de"
      },
      {
        year: 2002,
        text: "Das Potential von Reisegutscheinen wird entdeckt. Die ersten Reisen werden über touriDat auf ebay verkauft. Das Team wird erweitert und eine neue, größere Geschäftsstelle wird bezogen."
      },
      {
        year: 2006,
        text: "Der erste Webshop für Reisegutscheine geht online. Dieser Schritt verändert alles und neue, kreative Menschen bereichern das Team."
      },
      {
        year: 2009,
        text: "touriDat erweitert sich zum Ausbildungsbetrieb. Ambitionierte Menschen bekommen die Chance, sich zusammen mit einem Online-Unternehmen zu entwickeln."
      },
      {
        year: 2010,
        text: "Namhafte Privat-Hotels und die angesagtesten Hotelmarken befinden sich nun im Angebot von touriDat. Die Vielfalt an tollen Reiseangeboten wächst rasant. Eine alte Villa mit Park wird als zentrale Geschäftsstelle umgebaut und bietet dem Team Platz, neue Ideen umzusetzen"
      },
      {
        year: 2012,
        text: "Die erste Kreativ-Schmiede für neue Online-Projekte wird ins Leben gerufen. Alles dreht sich um Einfachheit, Eleganz und Fortschritt."
      },
      {
        year: 2014,
        text: "Einführung der touriDat-App und Veröffentlichung von touriDat-Deluxe. Es werden neue Abteilungen im Unternehmen gegründet. Nur die besten Köpfe unterstützen das Team."
      },
      {
        year: 2015,
        text: "touriDat hat sich zur attraktivsten und angesagtesten Online-Plattformen für Reisegutscheine in Europa entwickelt. Der Einstieg ins Sponsoring im Profi-Fußball folgte prompt. touriDat und der VfL Wolfsburg wollen den Frauenfußball fördern und attraktiver machen."
      },
      {
        year: 2018,
        text: "touriBook wird eingeführt und zum ersten Mal wird touriDat durch die Deutsche Gesellschaft für Verbraucherstudien in der Kategorie \"Reisedealanbieter\" ausgezeichnet"
      },
      {
        year: 2019,
        text: "Entwicklung und Umstellung eines eigenen Shopsystems. Zudem wird touriDat von der DtGV mit dem „Deutschen Kunden-Award 2019“ für das hervorragende Preis-Leistungs-Verhältnis ausgezeichnet und zum besten Anbieter gewählt."
      },
      {
        year: 2020,
        text: "touriDat wird von der DtGV mit dem „Deutschen Kunden-Award 2020/21“ für das hervorragende Preis-Leistungs-Verhältnis ausgezeichnet und zum besten Anbieter gewählt."
      },
      {
        year: 2022,
        text: "touriDays erfüllt die gesetzten Kriterien insgesamt am besten und wird erneut von der DtGV ausgezeichnet. Besonders in puncto Preisvorteil überzeugt das Portal und landet in dieser Kategorie ebenfalls auf dem ersten Platz."
      },
      {
        year: 2024,
        text: "Mit über 270.000 Bewertungen ist touriDat zudem erfolgreichster und beliebtester Anbieter von touriDays in Deutschland und wird von Trustami mit 5 von 5 Sternen ausgezeichnet."
      },
      {
        year: 2025,
        text: "Vielfältige & attraktive Angebote von Gutscheinen für Kurzreisen & Urlaube zu einmaligen Konditionen." + "\n" + "Unterkünfte in ganz Deutschland und weiteren Ländern, von wunderschönen Landhotels und inhabergeführten Privathotels, über luxuriöse Wellnesshotels bis zu stilvollen Markenhotels." + "\n" + "Themenbasierte Gutscheine, auch ideal zum Verschenken!"
      },
      {
        year: "20...",
        text: "Unser Kompass zeigt klar nach vorne: Die Reise geht weiter!"
      }
    ];
    const itemBackgroundClasses = [
      "bg-anniversary-darker",
      "bg-anniversary-dark",
      "bg-anniversary-basic",
      "bg-anniversary-light"
    ];
    return {
      itemBackgroundClasses,
      isHostSuperior,
      contestSession,
      timelineSlides
    };
  },
  metaInfo() {
    const description = "WIR FEIERN 30 JAHRE TOURIDAT | Melden Sie sich jetzt zum wunderbaren touriDat Jubiläumsgewinnspiel 2025 an, nutzen sie jeden Monat die Gewinnchance auf eine fantastische Reise und profitieren sie von grandiosen Rabatten.";
    const title = "Wir feiern 30 Jahre touriDat | touriDat Jubiläum 2025";
    return {
      title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: `${title} | touriDat.com`
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: description
        },
        {
          vmid: "keywords",
          name: "keywords",
          content:
            "jubiläum, gewinnspiel, touridat, 30 jahre, reisegutschein"
        },
        {
          vmid: "og:image",
          name: "og:image",
          content:
            "https://s3-cloud.td-cdn.de/Images/Promo/30-Jahre-TD/anniversary-2025-og-image.jpg"
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$touriConfig.server.host + "/30-jahre-jubilaeum"
        }
      ]
    };
  },
  data() {
    return {
      isLoading: true,
      showsWinnerPageLink: false, //toDo
      contest: undefined,
      orderDiscountCode: undefined,
      email: "",
      acceptedTerms: false,
      showSignupHints: false
    };
  },
  computed: {
    currentPrize() {
      const prizes = this.contest?.MarketingPromoContestPrizes || [];
      if (!prizes?.length) return;
      return prizes[0];
    },
    currentItem() {
      return this.currentPrize?.Item;
    },
    currentPrizeUrl() {
      const item = this.currentItem;
      if (!item?.Host?.Url || !item?.id) return;
      return `/gastgeber/${item.Host.Url}#${item.id}`;
    },
    /**
     * @returns {Array<{orderDiscountCode:Record<string,any>,item:Record<string,any>}>}
     */
    currentDiscountPrizes() {
      return this.contest?.CampaignTypes?.map((campaign) => {
        const item = campaign?.CategoryItems?.[0]?.Item;
        const orderDiscountCode = campaign?.OrderDiscountCodes?.[0];
        return { orderDiscountCode, item };
      });
    },
    currentDiscountPrizeCategory() {
      const campaignTypes = this.contest?.CampaignTypes;
      if (!campaignTypes?.length) return;
      const [campaign] = campaignTypes;
      return campaign.Category;
    }
  },
  methods: {
    getGalleryImages(prize) {
      if (!prize?.Item?.Host?.Images?.length) {
        return [].map((RemoteURL) => ({ RemoteURL }));
      }
      return getDisplayableHostImages(prize.Item.Host).slice(0, 3).reverse().map((image, index) => ({
        ...image,
        RemoteURL: `${image.RemoteURL}${index === 2 ? "large" : "small"}.jpg`
      }));
    },
    async loadContest() {
      return await this.$apollo.query({
        query: MarketingPromoContest.Queries.AnniversaryContest
      }).then((response) => response?.data?.anniversaryContest);
    },
    saveRouteQuery() {
      const { sharedBy, source } = this.$route.query;
      if (!sharedBy && !source) return;
      if (sharedBy) this.contestSession.set("referrerGuid", sharedBy);
      if (source) this.contestSession.set("source", source);
    }
  },
  async mounted() {
    this.saveRouteQuery();
    document.querySelector("body").classList.add("anniversary-page");
    EventBus.$emit("changeWebTemplateType", WebTemplatesEnum.Anniversary);
    try {
      this.contest = await this.loadContest();
    } finally {
      this.isLoading = false;
    }

  },
  unmounted() {
    document.querySelector("body").classList.remove("anniversary-page");
    EventBus.$emit("changeWebTemplateType", WebTemplatesEnum.Default);
  },
  destroyed() {
    document.querySelector("body").classList.remove("anniversary-page");
    document.querySelector("body").classList.remove("no-scroll");
    EventBus.$emit("changeWebTemplateType", WebTemplatesEnum.Default);
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/colors.scss";
@import "@/assets/styles/mediaqueries.scss";
@import "@/assets/styles/anniversary-colors";

.anniversary-page {
  background-color: $td-grey-darker;
  overflow-x: hidden;
  width: 100%;

  main {
    padding-bottom: 0 !important;
  }

  #mainNav {
    display: none !important;
  }
}

[data-template="anniversary"] {
  --theme-color-lighter: #{$color-anniversary-lighter};
  --theme-color-light: #{$color-anniversary-light};
  --theme-color-basic: #{$color-anniversary-basic};
  --theme-color-dark: #{$color-anniversary-dark};
  --theme-color-darker: #{$color-anniversary-darker};
}

.text-xl {
  font-size: 2.5rem;
  @include md {
    font-size: 4rem;
  }
}

@mixin td-button-bg-effect($bgcolor, $bg-image-color-1, $bg-image-color-2) {
  background: $bgcolor;
  background-image: linear-gradient(
      to right,
      $bg-image-color-1 50%,
      $bg-image-color-2 50%
  );
  background-size: 220% 100%;
  background-position: bottom left;
  &:hover {
    background-position: bottom right;
  }
}

@mixin td-shadow-inset($w1, $w2, $w3, $w4, $color) {
  box-shadow: inset $w1 $w2 $w3 $w4 $color;
}

.button-anniversary-dark {
  &.button-secondary {
    color: $td-grey-darker;
    @include td-button-bg-effect(transparent, transparent, $td-grey-darker);
    @include td-shadow-inset(0, 0, 0, 2px, $td-grey-darker);

    &:hover {
      color: $color-anniversary-light;
    }
  }
}

.button-anniversary {
  &.button-primary {
    color: $td-grey-darker;
    @include td-button-bg-effect($color-anniversary-basic, $color-anniversary-basic, $color-anniversary-dark);
  }

  &.button-secondary {
    color: $color-anniversary-light;
    @include td-button-bg-effect(transparent, transparent, $color-anniversary-light);
    @include td-shadow-inset(0, 0, 0, 2px, $color-anniversary-light);
  }

  &:hover {
    color: $td-grey-darker;
  }

  &[disabled] {
    color: $td-grey-dark;
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/styles/mediaqueries";
@import "@/assets/styles/colors";
@import "@/assets/styles/anniversary-colors";

.text-anniversary-light {
  color: $color-anniversary-light;
}

.text-anniversary-basic {
  color: $color-anniversary-basic;
}

.text-anniversary-dark {
  color: $color-anniversary-dark;
}

.text-anniversary-darker {
  color: $color-anniversary-darker;
}

.bg-anniversary-light {
  background-color: $color-anniversary-light;
}

.bg-anniversary-basic {
  background-color: $color-anniversary-basic;
}

.bg-anniversary-dark {
  background-color: $color-anniversary-dark;

  &.box {
    border-left: 4px solid $color-anniversary-light;
  }
}

.bg-anniversary-darker {
  background-color: $color-anniversary-darker;
}

.my-12 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.container-headline {
  text-align: center;
  position: relative;
  z-index: 0;
  font-weight: normal !important;
  font-size: 30px;
  @include lg {
    font-size: 40px;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    z-index: -1;
    isolation: isolate;
    height: 1px;
    background-color: #fff;
  }
}

.w-md-auto {
  @include md {
    width: auto !important;
  }
}

.text-xl {
  font-size: 1.5rem;
  @include md {
    font-size: 1.75rem;
  }
  @include lg {
    font-size: 1.875rem;
  }
}

#anniversary {
  font-size: 16px;
  @include md {
    font-size: 20px;
  }

  .logo-line {
    height: 75px;
    @include md {
      height: 90px;
    }
    @include lg {
      height: 180px;
    }
  }

  .logo-wrapper {
    .logo {
      min-width: initial;
    }
  }

  .ribbon-container {
    background: linear-gradient(90deg, $color-anniversary-dark 0%, $color-anniversary-light 25%, $color-anniversary-dark 75%, $color-anniversary-basic 100%);

    .ribbon {
      width: 162px;
      height: 130px;
      left: 50%;
      top: 33px;
      transform: translate(-50%, -50%);
    }
  }

  .image-gallery {
    .image img {
      width: 100%;
    }

    @include md {
      display: grid;
      gap: 0.25rem;
      grid-template-columns: 1fr 2fr;
      grid-template-rows: repeat(2, minmax(0, 1fr));

      .image:nth-child(3) {
        grid-row: span 2;
        overflow: hidden;
        aspect-ratio: 4 / 3.05;

        img {
          height: 100%;
          width: auto;
          max-width: calc(100% + 0.25rem);
          margin-left: -0.25rem;
        }
      }
    }


    .image {
      &:nth-child(2) {
        grid-area: 2 / 1;
      }

      &:not(:nth-child(3)) {
        display: none;
      }
    }
  }

  .discount-badge {
    --offset: 45px;
    position: absolute;
    background-color: $color-anniversary-dark;
    outline: 3px solid $color-anniversary-dark;
    outline-offset: 2px;
    right: var(--offset);
    top: var(--offset);
    border-radius: 50%;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 65px;

    @include md {
      width: 100px;
    }

    .percentage {
      position: relative;

      &:after {
        content: "²";
        font-size: 80%;
        position: absolute;
      }
    }
  }

  .discount-code-info {
    margin-bottom: 0;
    padding: 0;
    position: absolute;
    border-radius: 50%;
    aspect-ratio: 1;
    top: 0;
    right: 50%;
    transform: translate(50%, -50%);
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    @include xl {
      right: 15px;
    }

  }

  .code-text {
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.5)
  }

  .hr-faded {
    height: 1px;
    background: linear-gradient(90deg, rgba(34, 34, 34, 0) 0%, rgba(34, 34, 34, 1) 10%, rgba(34, 34, 34, 1) 90%, rgba(34, 34, 34, 0) 100%);
  }

  .arrow {
    margin-left: -6px;

    @include md {
      margin-left: initial;
    }
  }

  .value-badge {
    width: 105px;
    @include md {
      width: 130px;
    }
  }
}


</style>
