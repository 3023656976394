<template lang="pug">
  .d-block.discount-code-item
    .row
      .col-12.col-lg-6.pr-lg-0
        SmallHostGallery(:images="imageUrls")
      .col-12.col-lg-6.pl-lg-0.position-relative.d-grid(style="grid-template-rows: auto 1fr;")
        HostPreviewFooter.py-2.mb-2.txt-td-grey-darker(:persons="item.Persons" :nights="item.Nights" :catering="item.Catering" style="background-color:rgba(0,0,0,0.1);")
        .px-4.w-100.h-lg-100.d-flex.align-items-center
          .w-100
            .text-center
              .stars.d-inline-flex(v-if="item.Host.Stars" style="gap:0.25rem;font-size:90%;")
                font-awesome-icon(icon="star" v-for="n in parseInt(item.Host.Stars)")
                font-awesome-icon(icon="s" v-if="isHostSuperior(item.Host)")
              .name.h2.m-0 {{item.Host.Name}}
              .city-region.font-weight-semibold {{item.Host.CityRegion}}
              .item-name {{item.Name}}
            slot
            .text-center
              router-link.button.button-anniversary-dark.button-secondary.my-2(:to="itemUrl" target="_blank") mehr erfahren
</template>
<script>
import { defineComponent } from "vue";
import SmallHostGallery from "@/views/components/host/gallery/SmallHostGallery.vue";
import { getDisplayableHostImages, isHostSuperior } from "@/lib/helper/host";
import HostPreviewFooter from "@/views/components/hostPreview/partials/HostPreviewFooter.vue";

export default defineComponent({
  name: "DiscountCodeItem",
  components: { HostPreviewFooter, SmallHostGallery },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup() {
    return { isHostSuperior };
  },
  computed: {
    itemUrl() {
      return `/gastgeber/${this.item.Host.Url}#${this.item.id}`;
    },
    imageUrls() {
      return getDisplayableHostImages(this.item.Host).map((img) => img?.RemoteURL);
    }
  }
});
</script>
<style scoped lang="scss">

</style>
