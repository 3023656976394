import gql from "graphql-tag";

export const MarketingPromoContest = {
  Queries: {
    AnniversaryDiscountCode: gql`
        query AnniversaryDiscountCode {
            anniversaryDiscountCode {
                Code
                Value
                CampaignType {
                    id
                    CategoryItems {
                        id
                        Category {
                            Url
                        }
                        Item {
                            id
                            Name
                            Host {
                                Stars
                                Name
                                CityRegion
                                Images
                                Url
                            }
                        }
                    }
                }
            }
        }
    `,
    AnniversaryModalContest: gql`
        query AnniversaryContest($skipIncludes: Boolean) {
            anniversaryContest(skipIncludes: $skipIncludes) {
                id
            }
        }
    `,
    AnniversaryContest: gql`
        query AnniversaryContest {
            anniversaryContest {
                id
                Name
                MarketingPromoContestPrizes {
                    HtmlHeadline
                    HtmlSubline
                    Item {
                        id
                        Name
                        RegularPrice
                        Catering
                        Persons
                        Nights
                        Host {
                            id
                            Stars
                            Name
                            CityRegion
                            Url
                            Images
                        }
                    }
                }
                CampaignTypes {
                    id
                    Name
                    OrderDiscountCodes {
                        Code
                        Value
                    }
                    Category {
                        Name
                        Url
                    }
                    CategoryItems {
                        Item {
                            id
                            Name
                            Persons
                            Nights
                            Catering
                            Host {
                                id
                                Stars
                                Name
                                CityRegion
                                Url
                                Images
                            }
                        }
                    }
                }
            }
        }
    `,
    MarketingPromoContestPrizesByDrawPrizeAt: gql`
        query MarketingPromoContestPrizesByDrawPrizeAt($marketingPromoContestId: ID!, $drawPrizeAt: Date) {
            marketingPromoContestPrizesByDrawPrizeAt(marketingPromoContestId: $marketingPromoContestId, drawPrizeAt: $drawPrizeAt) {
                id
                IsDiscountOnlyPrize
                Value
                HtmlHeadline
                HtmlSubline
                HtmlDescription
                ImageUrl
                MarketingPromoParticipant {
                    MarketingPromoContestParticipants {
                        Ticket
                    }
                }
                Item {
                    id
                    Name
                    Persons
                    Nights
                    Catering
                    RegularPrice
                    Host {
                        Url
                        Images
                    }
                }
            }
        }
    `,
    MarketingPromoContest: gql`
        query MarketingPromoContest($marketingPromoContestId: ID!) {
            marketingPromoContest(id: $marketingPromoContestId) {
                id
                Name
                PrizeEnabledFrom
                PrizeEnabledTill
                RegistrationFrom
                RegistrationTill
                MarketingPromoContestPrizes {
                    id
                    DrawPrizeAt
                    PrizeDrawnAt
                }
            }
        }
    `,
    ListWinnerPrizes: gql`
        query ListMarketingPromoContestPrizes($ids: [ID!]!) {
            listMarketingPromoContestPrizes(ids: $ids) {
                id
                IsDiscountOnlyPrize
                Value
                PrizeDrawnAt
                DrawPrizeAt
                Item {
                    Name
                }
            }
        }
    `
  },
  Mutations: {
    SignupForContest: gql`
        mutation SignupForContest($contestId: ID!, $token: String!, $email: String!, $source: String, $referrerGuid: String) {
            signupForContest(contestId: $contestId, token: $token, email: $email, source: $source, referrerGuid: $referrerGuid) {
                id
                Ticket
                MarketingPromoParticipantReferrerId
            }
        }
    `
  }
};
