import AdventCalendarView from "@/views/static/adventCalendar/AdventCalendarView.vue";
import AdventCalendarWinners from "@/views/static/adventCalendar/AdventCalendarWinners.vue";
import TestView from "@/views/static/easter/TestView.vue";
import AdventCalendarSignup from "@/views/static/adventCalendar/AdventCalendarSignup.vue";
import AnniversaryView from "@/views/static/anniversary/AnniversaryView.vue";

export const useSeasonalRoutes = () => {
  return [
    {
      path: "/adventskalender",
      alias: ["/adventskalender-2024", "/xmas-2024"],
      name: "AdventCalendarView",
      component: AdventCalendarView,
      children: [
        {
          path: "/",
          name: "AdventCalendar",
          component: AdventCalendarSignup
        },
        {
          path: "gewinner",
          component: AdventCalendarWinners,
          name: "AdventCalendarWinners"
        }
      ]
    },
    {
      path: "/easter",
      component: TestView
    }, {
      path: "/30-jahre-jubilaeum",
      alias: ["/jubilaeum"],
      name: "AnniversaryView",
      component: AnniversaryView
    }
  ];
};
